// 判断大于0正整数
function isIntegerNonZero(rule, value, callback) {
    if (!/^[1-9]\d*$/.test(value)) {
        callback(new Error('请输入大于0的数字'));
    } else {
        callback();
    }
}
// 判断正整数
function isInteger(rule, value, callback) {
    if (value==0 || /^[1-9]\d*$/.test(value)) {
        callback();
    } else {
        callback(new Error('请输入整数'));
    }
}
// 判断浮点数
function isFloat(rule, value, callback) {
    if (!/^(\d+)(\.\d+)?$/.test(value)) {
        callback(new Error('请输入大于0的数字'));
    } else if(value>0) {
        callback();
    } else {
        callback(new Error('请输入大于0的数字'));
    }
}

export default {
    isIntegerNonZero,
    isInteger,
    isFloat,
}
