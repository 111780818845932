import shop from '@/service/shop'
import merchant from '@/service/merchant'
import news from '@/service/news'
import goods from "@/service/goods";
import member from "@/service/member";
import weixin from "@/service/weixin";
import marking from "@/service/marking";
import order from "@/service/order";
import distribution from "@/service/distribution";
import channel from "@/service/channel";
import statics from "@/service/statics";

export default {
  shop,
  merchant,
  news,
  goods,
  member,
  weixin,
  marking,
  order,
  distribution,
  channel,
  statics
}
