import layout from '../view/layout'

const router = {
  name: 'distribution',
  path: 'distribution',
  component: layout,
  meta: {
    title: '分销',
    ico: 'el-icon-share',
    authkey: 'distribution'
  },
  children: [
    {
      name: 'distribution_guider',
      path: 'guider',
      component: () => import('../view/layout'),
      meta: {
        title: '推客',
        ico: '',
        isShow: true,
        authkey: 'distribution_guider'
      },
      children: [
        {
          name: 'distribution_guider_home',
          path: 'home',
          component: () => import('../view/distribution/guider/home'),
          meta: {
            title: '推客概况',
            ico: '',
            isShow: true,
            authkey: 'distribution_guider_home'
          }
        },
        {
          name: 'distribution_guider_member',
          path: 'member',
          component: () => import('../view/distribution/guider/member'),
          meta: {
            title: '推客会员',
            ico: '',
            isShow: true,
            authkey: 'distribution_guider_member'
          }
        },
        {
          name: 'distribution_guider_order',
          path: 'order',
          component: () => import('../view/distribution/guider/order'),
          meta: {
            title: '推客订单',
            ico: '',
            isShow: true,
            authkey: 'distribution_guider_order'
          }
        },
        {
          name: 'distribution_guider_settle',
          path: 'settle',
          component: () => import('../view/distribution/guider/settle'),
          meta: {
            title: '结算记录',
            ico: '',
            isShow: true,
            authkey: 'distribution_guider_settle'
          }
        },
        {
          name: 'distribution_guider_set',
          path: 'set',
          component: () => import('../view/distribution/guider/set'),
          meta: {
            title: '推客设置',
            ico: '',
            isShow: true,
            authkey: 'distribution_guider_set'
          }
        }
      ]
    },
    {
      name: 'distribution_team',
      path: 'team',
      component: () => import('../view/layout'),
      meta: {
        title: '团队',
        ico: '',
        isShow: true,
        authkey: 'distribution_team'
      },
      children: [
        {
          name: 'distribution_team_home',
          path: 'home',
          component: () => import('../view/distribution/team/home'),
          meta: {
            title: '团队概况',
            ico: '',
            isShow: true,
            authkey: 'distribution_team_home'
          }
        },
        {
          name: 'distribution_team_member',
          path: 'member',
          component: () => import('../view/distribution/team/member'),
          meta: {
            title: '团长成员',
            ico: '',
            isShow: true,
            authkey: 'distribution_team_member'
          }
        },
        {
          name: 'distribution_team_order',
          path: 'order',
          component: () => import('../view/distribution/team/order'),
          meta: {
            title: '团队订单',
            ico: '',
            isShow: true,
            authkey: 'distribution_team_order'
          }
        },
        {
          name: 'distribution_team_settle',
          path: 'settle',
          component: () => import('../view/distribution/team/settle'),
          meta: {
            title: '结算记录',
            ico: '',
            isShow: true,
            authkey: 'distribution_team_settle'
          }
        },
        {
          name: 'distribution_team_set',
          path: 'set',
          component: () => import('../view/distribution/team/set'),
          meta: {
            title: '团队设置',
            ico: '',
            isShow: true,
            authkey: 'distribution_team_set'
          }
        }
      ]
    }
  ]
}

export default router
