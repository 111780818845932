import layout from '../view/layout'

const router = {
  name: 'person',
  path: 'person',
  component: layout,
  meta: {
    title: '首页',
    ico: 'el-icon-s-home',
    authkey: ''
  },
  children: [
    {
      name: 'index',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '首页',
        ico: '',
        isShow: true
      },
      children: [
        {
          name: 'home_index',
          path: '/home',
          component: () => import('../view/index'),
          meta: {
            title: '概况',
            ico: '',
            isShow: true,
            authkey: ''
          }
        },
        {
          name: 'person_index',
          path: 'index',
          component: () => import('../view/person/index'),
          meta: {
            title: '个人信息',
            ico: '',
            isShow: true,
            authkey: ''
          }
        },
        {
          name: 'person_resetpwd',
          path: 'resetpwd',
          component: () => import('../view/person/resetpwd'),
          meta: {
            title: '修改密码',
            ico: '',
            isShow: true,
            authkey: 'resetpwd'
          }
        }
      ]
    }
  ]
}

export default router
