import { fetch } from '@/utils/request';

// 推客
const guiderHome = (param, callback) => fetch('/shop/guider/home','GET', param, callback);
const guiderMember = (param, callback) => fetch('/shop/guider/member/index','GET', param, callback);
const guiderMemberVerify = (param, callback) => fetch('/shop/guider/member/verify','POST', param, callback);
const guiderMemberStatus = (param, callback) => fetch('/shop/guider/member/status','POST', param, callback);
const guiderMemberGrade = (param, callback) => fetch('/shop/guider/member/grade','POST', param, callback);
const guiderMemberChangeParent = (param, callback) => fetch('/shop/guider/member/changeParent','POST', param, callback);
const guiderOrder = (param, callback) => fetch('/shop/guider/order','GET', param, callback);
const guiderSettle = (param, callback) => fetch('/shop/guider/settle','GET', param, callback);
const guiderSetInfo = (param, callback) => fetch('/shop/guider/set','GET', param, callback);
const guiderSetSave = (param, callback) => fetch('/shop/guider/set','POST', param, callback);
const guiderWithdrawSetSave = (param, callback) => fetch('/shop/guider/withdrawSet','POST', param, callback);
const guiderTjCommission = (param, callback) => fetch('/shop/guider/tj/commission','GET', param, callback);
const guiderTjGuider = (param, callback) => fetch('/shop/guider/tj/guider','GET', param, callback);

// 推客等级
const guiderGradeIndex = (param, callback) => fetch('/shop/guider/grade/index','GET', param, callback);
const guiderGradeInfo = (param, callback) => fetch('/shop/guider/grade/detail','GET', param, callback);
const guiderGradeEdit = (param, callback) => fetch('/shop/guider/grade/edit','POST', param, callback);
const guiderGradeDel = (param, callback) => fetch('/shop/guider/grade/del','POST', param, callback);

// 推客订单
const guiderOrderIndex = (param, callback) => fetch('/shop/guider/order/index','GET', param, callback);

// 结算记录
const guiderSettleIndex = (param, callback) => fetch('/shop/guider/settle/index','GET', param, callback);

export default {
  guiderHome,
  guiderMember,
  guiderMemberVerify,
  guiderMemberStatus,
  guiderMemberGrade,
  guiderMemberChangeParent,
  guiderOrder,
  guiderSettle,
  guiderSetInfo,
  guiderSetSave,
  guiderWithdrawSetSave,
  guiderTjCommission,
  guiderTjGuider,

  guiderGradeIndex,
  guiderGradeInfo,
  guiderGradeEdit,
  guiderGradeDel,

  guiderOrderIndex,
  guiderSettleIndex

}
