import layout from '../view/layout'

const router = {
  name: 'trade',
  path: 'trade',
  component: layout,
  meta: {
    title: '交易',
    ico: 'el-icon-coin',
    authkey: 'trade'
  },
  children: [
    {
      name: 'trade_tab',
      path: 'tab',
      component: () => import('../view/layout'),
      meta: {
        title: '交易',
        ico: '',
        isShow: true,
        authkey: 'trade_tab'
      },
      children: [
        {
          name: 'trade_view',
          path: 'view',
          component: () => import('../view/trade/view'),
          meta: {
            title: '交易概况',
            ico: '',
            isShow: true,
            authkey: 'trade_view'
          }
        },
        {
          name: 'trade_flow',
          path: 'flow',
          component: () => import('../view/trade/flow'),
          meta: {
            title: '资金流水',
            ico: '',
            isShow: true,
            authkey: 'trade_flow'
          }
        },
        {
          name: 'trade_recharge',
          path: 'recharge',
          component: () => import('../view/trade/recharge'),
          meta: {
            title: '充值记录',
            ico: '',
            isShow: true,
            authkey: 'trade_recharge'
          }
        }
      ]
    },
    {
      name: 'trade_finance',
      path: 'finance',
      component: () => import('../view/layout'),
      meta: {
        title: '财务',
        ico: '',
        isShow: true,
        authkey: 'trade_finance'
      },
      children: [
        {
          name: 'trade_finance_withdraw',
          path: 'withdraw',
          component: () => import('../view/trade/finance/withdraw'),
          meta: {
            title: '提现管理',
            ico: '',
            isShow: true,
            authkey: 'trade_finance_withdraw'
          }
        },
        {
          name: 'trade_finance_invoice',
          path: 'invoice',
          component: () => import('../view/trade/finance/invoice'),
          meta: {
            title: '发票管理',
            ico: '',
            isShow: true,
            authkey: 'trade_finance_invoice'
          }
        }
      ]
    }
  ]
}

export default router
