import { fetch } from '@/utils/request';

// 微信图文
const weixinReplyIndex = (param, callback) => fetch('/shop/weixin/reply/index','GET', param, callback);
const weixinReplyInfo = (param, callback) => fetch('/shop/weixin/reply/detail','GET', param, callback);
const weixinReplyAdd = (param, callback) => fetch('/shop/weixin/reply/add','POST', param, callback);
const weixinReplyEdit = (param, callback) => fetch('/shop/weixin/reply/edit','POST', param, callback);
const weixinReplyDel = (param, callback) => fetch('/shop/weixin/reply/del','POST', param, callback);
const weixinReplyShenhe = (param, callback) => fetch('/shop/weixin/reply/shenhe','POST', param, callback);
const weixinReplyIsDefault = (param, callback) => fetch('/shop/weixin/reply/isDefault','POST', param, callback);
const weixinReplyIsFollow = (param, callback) => fetch('/shop/weixin/reply/isFollow','POST', param, callback);

// 微信菜单
const weixinMenu = (param, callback) => fetch('/shop/weixin/menu','GET', param, callback);
const weixinMenuSave = (param, callback) => fetch('/shop/weixin/menu/save','POST', param, callback);

export default {
  weixinReplyIndex,
  weixinReplyInfo,
  weixinReplyAdd,
  weixinReplyEdit,
  weixinReplyDel,
  weixinReplyShenhe,
  weixinReplyIsDefault,
  weixinReplyIsFollow,
  weixinMenu,
  weixinMenuSave

}
