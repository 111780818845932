import layout from '../view/layout'

const router = {
  name: 'sys',
  path: 'sys',
  component: layout,
  meta: {
    title: '系统',
    ico: 'el-icon-s-tools',
    authkey: 'sys'
  },
  children: [
    {
      name: 'sys_set_layout',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        route_name: 'sys_merchant_info',
        title: '设置',
        ico: '',
        isShow: true,
        authkey: 'sys_set_box'
      },
      children: [
        {
          name: 'sys_set',
          path: 'set',
          component: () => import('../view/sys/set'),
          meta: {
            route_name: 'sys_merchant_info',
            title: '商户设置',
            ico: '',
            isShow: true,
            authkey: 'sys_merchant_info'
          }
        },
        {
          name: 'payment_set',
          path: 'payment',
          component: () => import('../view/sys/payment'),
          meta: {
            route_name: 'payment_set',
            title: '支付配置',
            ico: '',
            isShow: true,
            authkey: 'payment_set'
          }
        },
        {
          name: 'delivery_set',
          path: 'delivery',
          component: () => import('../view/sys/deliveryset'),
          meta: {
            route_name: 'delivery_set',
            title: '物流配置',
            ico: '',
            isShow: true,
            authkey: 'delivery_set'
          }
        },
        {
          name: 'secret_set',
          path: 'secret',
          component: () => import('../view/sys/secretset'),
          meta: {
            route_name: 'secret_set',
            title: '协议规则',
            ico: '',
            isShow: true,
            authkey: 'secret_set'
          }
        },
        {
          name: 'shop_store',
          path: 'store',
          component: () => import('../view/sys/store'),
          meta: {
            route_name: 'shop_store',
            title: '自提点',
            ico: '',
            isShow: true,
            authkey: 'merchant_store'
          }
        },
        {
          name: 'shop_store_edit',
          path: 'store/edit',
          component: () => import('../view/sys/store_edit'),
          meta: {
            route_name: 'shop_store',
            title: '编辑自提点',
            ico: '',
            isShow: false,
            authkey: 'merchant_store'
          }
        },
        {
          name: 'message_manage',
          path: 'message/manage',
          component: () => import('../view/sys/message_manage'),
          meta: {
            route_name: 'message_manage',
            title: '消息管理',
            ico: '',
            isShow: true,
            authkey: 'message_manage'
          }
        }
      ]
    },
    {
      name: 'user',
      path: 'user',
      component: () => import('../view/layout'),
      meta: {
        title: '管理员',
        ico: 'el-icon-user-solid',
        isShow: true,
        authkey: 'user'
      },
      children: [
        {
          name: 'user_index',
          path: 'index',
          component: () => import('../view/sys/user/index'),
          meta: {
            title: '账户列表',
            ico: '',
            isShow: true,
            authkey: 'user_index'
          }
        },
        {
          name: 'user_add',
          path: 'add',
          component: () => import('../view/sys/user/add'),
          meta: {
            title: '账户添加',
            ico: '',
            isShow: false,
            authkey: 'user_index'
          }
        },
        {
          name: 'user_group',
          path: 'group',
          component: () => import('../view/sys/user/group'),
          meta: {
            title: '群组管理',
            ico: '',
            isShow: true,
            authkey: 'user_group'
          }
        },
        {
          name: 'user_group_add',
          path: 'groupadd',
          component: () => import('../view/sys/user/groupadd'),
          meta: {
            title: '群组添加',
            ico: '',
            isShow: false,
            authkey: 'user_group'
          }
        }
      ]
    },
    {
      name: 'notice',
      path: 'notice',
      component: () => import('../view/layout'),
      meta: {
        title: '通知',
        ico: 'el-icon-user-solid',
        isShow: true,
        authkey: 'notice'
      },
      children: [
        {
          name: 'sys_notice_system',
          path: 'system',
          component: () => import('../view/sys/notice/notcie_system'),
          meta: {
            title: '系统消息',
            ico: '',
            isShow: true,
            authkey: 'notcie_system'
          }
        },
        {
          name: 'sys_notice_merchant',
          path: 'merchant',
          component: () => import('../view/sys/notice/notice_merchant'),
          meta: {
            title: '商户消息',
            ico: '',
            isShow: true,
            authkey: 'notice_merchant'
          }
        }
      ]
    },
    {
      name: 'static',
      path: 'static',
      component: () => import('../view/layout'),
      meta: {
        title: '统计',
        ico: 'el-icon-user-solid',
        isShow: true,
        authkey: 'sys_static_box'
      },
      children: [
        {
          name: 'sys_static_visit',
          path: 'visit',
          component: () => import('../view/sys/static/visit'),
          meta: {
            title: '浏览分析',
            ico: '',
            isShow: true,
            authkey: 'sys_static_visit'
          }
        }
      ]
    }
  ]
}

export default router
