import Vue from 'vue';
import App from './App';
import { router } from './router/router';
import config from './config/config'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// import '@/assets/ele-ui/index.css'
Vue.use(ElementUI);
import utils from './utils/util'
import validate from './utils/validate'

import { filters } from './utils/filters.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});

Vue.prototype.$validate = validate
import api from './service/api';
Vue.prototype.$api = api;
import configure from './config/configure'
Vue.prototype.$configure = configure

Vue.prototype.showLoading = utils.showLoading
Vue.prototype.hideLoading = utils.hideLoading
Vue.prototype.tips = utils.tips
Vue.prototype.success = utils.success
Vue.prototype.fail = utils.fail
Vue.prototype.reBack = utils.reBack
Vue.prototype.isEmpty = utils.isEmpty
Vue.prototype.checkPermission = utils.checkPermission
Vue.prototype.config = config

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});

