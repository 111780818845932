import config from '../config/config'

let CACHE_TOKEN = config.CACHE_TOKEN

// token
const getToken = ()=> window.localStorage.getItem(CACHE_TOKEN)
const setToken = (token) => window.localStorage.setItem(CACHE_TOKEN, token)
const removeToken =()=> window.localStorage.removeItem(CACHE_TOKEN)

// 功能权限
const getFuncPriv = ()=> window.localStorage.getItem('funprv')
const setFuncPriv = (data) => window.localStorage.setItem('funprv', data)

export {
    getToken,
    setToken,
    removeToken,
    getFuncPriv,
    setFuncPriv
}
