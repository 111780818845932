import layout from '../view/layout'

const router = {
  name: 'order',
  path: 'order',
  component: layout,
  meta: {
    title: '订单',
    ico: 'el-icon-s-order',
    authkey: 'order'
  },
  children: [
    {
      name: '',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '订单',
        ico: '',
        isShow: true,
        authkey: 'order_box'
      },
      children: [
        {
          name: 'order_index',
          path: 'index',
          component: () => import('../view/order/index'),
          meta: {
            title: '所有订单',
            ico: '',
            isShow: true,
            authkey: 'order_index'
          }
        },
        {
          name: 'order_send',
          path: 'send',
          component: () => import('../view/order/order_send'),
          meta: {
            title: '待发货订单',
            ico: '',
            isShow: true,
            authkey: 'order_send'
          }
        },
        {
          name: 'order_selffetch',
          path: 'selffetch',
          component: () => import('../view/order/order_selffetch'),
          meta: {
            title: '上门自提订单',
            ico: '',
            isShow: true,
            authkey: 'order_selffetch'
          }
        },
        {
          name: 'order_presale',
          path: 'presale',
          component: () => import('../view/order/order_presale'),
          meta: {
            title: '预售订单',
            ico: '',
            isShow: true,
            authkey: 'order_presale'
          }
        },
        {
          name: 'order_refund',
          path: 'refund',
          component: () => import('../view/order/order_refund'),
          meta: {
            title: '维权订单',
            ico: '',
            isShow: true,
            authkey: 'order_refund'
          }
        },
        {
          name: 'order_hexiao',
          path: 'hexiao',
          component: () => import('../view/order/order_hexiao'),
          meta: {
            title: '订单核销',
            ico: '',
            isShow: true,
            authkey: 'order_hexiao'
          }
        },
        {
          name: 'order_detail',
          path: 'detail/:id',
          component: () => import('../view/order/detail'),
          meta: {
            title: '订单详情',
            ico: '',
            isShow: false,
            authkey: ''
          }
        },
        {
          name: 'order_refund_detail',
          path: 'refund/detail/:id',
          component: () => import('../view/order/refund_detail'),
          meta: {
            title: '退款详情',
            ico: '',
            isShow: false,
            authkey: ''
          }
        }
      ]
    }
  ]
}

export default router
