import { fetch } from '@/utils/request';

// 微信菜单
const visitFans = (param, callback) => fetch('/shop/statics/visit/fans','GET', param, callback);
const visitLog = (param, callback) => fetch('/shop/statics/visit/log','GET', param, callback);

export default {
  visitFans,
  visitLog

}
