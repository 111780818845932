import layout from '../view/layout'

const router = {
  name: 'cashier',
  path: 'cashier',
  component: layout,
  meta: {
    title: '收银台',
    ico: 'el-icon-s-finance',
    authkey: 'cashier'
  },
  children: [
    {
      name: 'cashier_tab',
      path: 'tab',
      component: () => import('../view/cashier/index'),
      meta: {
        title: '收银',
        ico: '',
        isShow: true,
        authkey: ''
      },
      children: [
        {
          name: 'trade_view',
          path: 'view',
          component: () => import('../view/trade/view'),
          meta: {
            title: '交易1概况',
            ico: '',
            isShow: true,
            authkey: ''
          }
        },
        {
          name: 'trade_flow',
          path: 'flow',
          component: () => import('../view/trade/flow'),
          meta: {
            title: '资金1流水',
            ico: '',
            isShow: true,
            authkey: ''
          }
        },
      ]
    }
  ]
}

export default router
