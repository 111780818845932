export const filters = {
	//截取字符串
	truncate: (value, length) => {
		if(!value) {
			return ''
		}
		if (value.length > length) {
			return value.substring(0, length)
		} else {
			return value
		}
	}
}