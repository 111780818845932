import { fetch } from '@/utils/request';

// 订单
const orderIndex = (param, callback) => fetch('/shop/order/index','GET', param, callback); //列表
const orderDetail = (param, callback) => fetch('/shop/order/detail','GET', param, callback); //详情
const orderCancel = (param, callback) => fetch('/shop/order/cancel','POST', param, callback); //取消
const orderDelivery = (param, callback) => fetch('/shop/order/delivery','POST', param, callback); //发货
// const orderSetump = (param, callback) => fetch('/shop/order/setump','POST', param, callback);
// const orderInvoice = (param, callback) => fetch('/shop/order/invoice','POST', param, callback);
// const orderShipmentsLog = (param, callback) => fetch('/shop/order/shipments/log','GET', param, callback);
const orderHexiao = (param, callback) => fetch('/shop/order/hexiao','POST', param, callback); //核销
const orderSelffetchLog = (param, callback) => fetch('/shop/order/selffetch/log','GET', param, callback); //上门自提记录
const orderDeliveryLog = (param, callback) => fetch('/shop/order/delivery/log','GET', param, callback); //订单物流信息

// 退款
const refundDetail = (param, callback) => fetch('/shop/refund/detail','GET', param, callback); //详情
const refundAgree = (param, callback) => fetch('/shop/refund/agree','POST', param, callback); //退款同意/拒绝
const refundClose = (param, callback) => fetch('/shop/refund/close','POST', param, callback); //关闭退款
const refundSure = (param, callback) => fetch('/shop/refund/sure','POST', param, callback); //确认收货

// 交易
const orderTradeTjIndex = (param, callback) => fetch('/shop/order/trade/tj/index','GET', param, callback); //交易统计
const orderTradeRecharge = (param, callback) => fetch('/shop/order/trade/recharge','GET', param, callback); //充值列表
const orderTradeIndex = (param, callback) => fetch('/shop/order/trade/index','GET', param, callback); //交易列表
const orderTradeTjTime = (param, callback) => fetch('/shop/order/trade/tj/time','GET', param, callback); //订单交易统计

// 提现
const financeWithdrawIndex = (param, callback) => fetch('/shop/finance/withdraw/index','GET', param, callback); //提现列表
const financeWithdrawOperate = (param, callback) => fetch('/shop/finance/withdraw/operate','POST', param, callback); //提现操作

export default {
    orderIndex,
    orderDetail,
    orderDeliveryLog,
    orderCancel,
    orderDelivery,
    orderHexiao,
    orderSelffetchLog,
    refundDetail,
    refundAgree,
    refundClose,
    refundSure,
    // orderSetump,
    // orderInvoice,
    // orderShipmentsLog
    orderTradeTjIndex,
    orderTradeRecharge,
    orderTradeIndex,
    orderTradeTjTime,
    financeWithdrawIndex,
    financeWithdrawOperate
}
