import layout from '../view/layout'

const router = {
  name: 'shop',
  path: 'shop',
  component: layout,
  meta: {
    title: '店铺',
    ico: 'el-icon-goods',
    authkey: 'shop'
  },
  children: [
    {
      name: '',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '店铺',
        ico: '',
        isShow: true,
        authkey: 'shop'
      },
      children: [
        {
          name: 'shop_set',
          path: 'set',
          component: () => import('../view/shop/set'),
          meta: {
            title: '店铺设置',
            ico: '',
            isShow: true,
            authkey: 'shop_set'
          }
        },
        {
          name: 'shop_design',
          path: 'design',
          component: () => import('../view/shop/design_index'),
          meta: {
            title: '店铺装修',
            ico: '',
            isShow: true,
            authkey: 'shop_design'
          }
        },
        {
          name: 'shop_design_info',
          path: 'design/info',
          component: () => import('../view/shop/design_info'),
          meta: {
            title: '店铺装修',
            ico: '',
            isShow: false,
            authkey: 'shop_design'
          }
        },
        {
          name: 'shop_design_nav',
          path: 'design/nav',
          component: () => import('../view/shop/design_nav'),
          meta: {
            title: '底部菜单',
            ico: '',
            isShow: true,
            authkey: 'shop_design_nav'
          }
        },
        {
          name: 'shop_design_member',
          path: 'design/member',
          component: () => import('../view/shop/design_member'),
          meta: {
            title: '个人中心页',
            ico: '',
            isShow: true,
            authkey: 'shop_design_member'
          }
        },
        {
          name: 'shop_design_menu',
          path: 'design/menu',
          component: () => import('../view/shop/design_goods_menu'),
          meta: {
            title: '样式配置',
            ico: '',
            isShow: true,
            authkey: 'shop_design_menu'
          }
        },
        {
          name: 'shop_design_order',
          path: 'design/order',
          component: () => import('../view/shop/design_order'),
          meta: {
            title: '订单侧边栏',
            ico: '',
            isShow: true,
            authkey: 'shop_design_order'
          }
        },
        {
          name: 'shop_design_goods',
          path: 'design/goods',
          component: () => import('../view/shop/design_goods'),
          meta: {
            title: '商品侧边栏',
            ico: '',
            isShow: true,
            authkey: 'shop_design_goods'
          }
        },
        {
          name: 'shop_book',
          path: 'book',
          component: () => import('../view/shop/book'),
          meta: {
            title: '报名管理',
            ico: '',
            isShow: true,
            authkey: 'shop_book'
          }
        }
      ]
    }
  ]
}

export default router
