import Vue from 'vue';
import Router from 'vue-router';
import main from '../view/main'
import NotFoundComponent from '../view/404'
import personRouter from '../router/person.router'
import shopRouter from '../router/shop.router'
import goodsRouter from '../router/goods.router'
import orderRouter from '../router/order.router'
import sysRouter from '../router/sys.router'
import memberRouter from '../router/member.router'
import markingRouter from "@/router/marking.router";
import channelRouter from "@/router/channel.router";
import tradeRouter from "@/router/trade.router";
import distributionRouter from "@/router/distribution.router";
import cashierRouter from "@/router/cashier.router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(Router);

const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('../view/login'),
    meta: { title: '登录' }
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    name: 'home',
    path: '/',
    component: main,
    redirect: '/home',
    children: [

    ]
  },
  {
    name: 'cashier',
    path: '/cashier',
    component: () => import('../view/cashier'),
    // redirect: '/cashier',
    children: [

    ]
  },
  {
    path: '*',
    component: NotFoundComponent
  }
];

routes[2].children.push(personRouter)
routes[2].children.push(shopRouter)
routes[2].children.push(goodsRouter)
routes[2].children.push(orderRouter)
routes[2].children.push(memberRouter)
routes[2].children.push(markingRouter)
routes[2].children.push(distributionRouter)
routes[2].children.push(channelRouter)
routes[2].children.push(tradeRouter)
// routes[3].children.push(cashierRouter)
routes[2].children.push(sysRouter)

// add route path
// routes.forEach(route => {
  // route.path = route.path || '/' + (route.name || '');
// });

const RouterConfig = {
  // mode: 'hash',
  mode: 'history',
  routes: routes
}
const router = new Router(RouterConfig);

router.beforeEach((to, from, next) => {
  // const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = title;
  // }
  NProgress.start()
  next();
});

router.afterEach(() => {
  NProgress.done()
})

NProgress.configure({ease:'linear',speed: 500});

export {
  router
};
