<template>
  <div class="layout">
    <div class="tab-layout">
      <div class="tab-one">
        <div class="tab-logo"><i class="el-icon-cloudy"></i></div>
        <ul>
          <li :class="item.active ? 'active' : ''" v-for="(item, index) in parent" @click="changeOne(index)" :key="item.id">
            <i :class="item.meta.ico"></i>
            <div class="txt">{{ item.meta.title }}</div>
          </li>
        </ul>
      </div>
      <div class="tab-two">
        <div class="title">管理中心</div>
        <div class="two-box">
          <div v-for="(item, index) in child" :key="index">
            <div class="divider"><div class="divider_txt">{{ item.meta.title }}</div></div>
            <ul>
              <li :class="item2.active ? 'active' : ''" @click="changeTwo(index, cindex)" v-for="(item2, cindex) in item.children" :key="cindex">
                <i :class="item2.meta.ico"></i>
                <span class="txt">{{ item2.meta.title }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="top-layout">
      <div class="nav">
        <span class="menu">
          <i class="el-icon-s-fold showico"></i>
          <!--i class="el-icon-s-unfold showico"></i-->
          <span v-for="(item, index) in bread_crumb" :key="item.id">
            <span role="presentation" class="el-breadcrumb__separator" v-if="index>0">&gt;</span>
            <span><span>{{ item.meta.title }}</span></span>
          </span>
        </span>
        <span class="uhead">
          <el-dropdown @command="handleDrop" v-if="checkPermission('notice')">
             <span class="badge-box pt">
              <el-badge :value="config.notice_sum" :max="99" class="badge">
                <i class="el-icon-bell"></i>
              </el-badge>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="system_notice" v-if="checkPermission('notcie_system')">系统信息（{{config.notice_system_sum}}）</el-dropdown-item>
              <el-dropdown-item command="merchant_notice" v-if="checkPermission('notice_merchant')">商户消息（{{config.notice_merchant_sum}}）</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-avatar size="medium" :src="circleUrl" class="img"></el-avatar>
          <el-dropdown @command="handleDrop">
            <span class="el-dropdown-link pt">
              个人中心<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-user" command="myinfo">我的信息</el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button" command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
      <!--div class="tab">
        <ul>
          <li>
            <i class="el-icon-c-scale-to-original"></i>
            <span>首页</span>
            <i class="el-icon-close close" style="display: none;"></i>
          </li>
          <li>
            <i class="el-icon-c-scale-to-original"></i>
            <span>首页</span>
            <i class="el-icon-close close" style="display: none;"></i>
          </li>
        </ul>
      </div-->
    </div>
    <audio ref="audioPlayer" :src="source_domain + '/audio/rem.mp3'" style="display: none;"></audio>
    <div class="body-layout">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>

import {getToken, removeToken, setFuncPriv} from '@/utils/auth'
import {EventBus} from "@/utils/eventBus";
export default {
  components: {
  },
  data() {
    return {
      info: false,
      config: false,
      source_domain: this.config.SOURCE_DOMAIN,
      circleUrl: this.config.SOURCE_DOMAIN + "/images/head.png",
      privs: [],
      parent: [],
      child: [],
      three: [],
      bread_crumb: [],
      socketNum: 1,
      isConnecting: false,
      ws: false
    };
  },
  created() {
    this.getConfig()
    this.getMyPrivs();
    this.getUserInfo()
    EventBus.$on('updateNotice',res=>{
      if(res.type=='notice') {
        this.getConfig()
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('updateNotice')
  },
  mounted() {
  },
  computed: {
  },
  watch: {
    $route(to, from){
      var _this = this
      if(to.meta.authkey && !this.in_array(to.meta.authkey,this.privs)) {
        this.fail('当前页面无权限')
        setTimeout(function () {
          _this.changeOne(0)
        },1000)
        return false
      }
    },
  },
  methods: {
    initData() {
      // setInterval(r=>{
      //   this.$refs.audioPlayer.play().catch(error => {
      //     console.error('无法播放音频:', error);
      //   });
      //   this.$notify({
      //     title: '自定义位置',
      //     message: '左下角弹出的消息',
      //     position: 'bottom-right'
      //   });
      // },120000)
    },
    initSocket() {
      if(this.isConnecting || this.ws) {
        return false
      }
      this.isConnecting = true
      if ('WebSocket' in window){
        this.ws = new WebSocket(this.config.wss+'?source=merchant&user='+getToken('MER_TOKEN'));
        this.ws.onmessage =  (res=>{
          console.log("数据已接收...",JSON.parse(res.data));
          var data = JSON.parse(res.data)
          this.$refs.audioPlayer.play().catch(error => {
            console.error('无法播放音频:', error);
          });
          this.$notify({
            title: data.title,
            message: data.content,
            position: 'bottom-right',
            duration: 4000
          });
          this.getConfig()
        })
        this.ws.onopen=(res=>{
          this.isConnecting = false
          this.socketNum = 1
          console.log(res)
        })
        this.ws.onclose=(res=>{
          this.ws = false
          this.isConnecting = false
          console.log(res)
          setTimeout(r=>{
            this.initSocket()
          },this.socketNum*1000)
        })
        this.ws.onerror=(res=>{
          this.ws = false
          this.socketNum ++
          console.log(res)
        })
      }else{
        console.log('当前浏览器 Not support websocket')
      }
    },
    initRouter(router) {
      var _this = this
      router = router.filter(function (item) {
        if(item.meta.authkey && !_this.in_array(item.meta.authkey,_this.privs)) {
          return false
        }
        if(item.children && item.children.length>0) {
          item.children = item.children.filter(function (item2) {
            if(item2.meta.authkey && !_this.in_array(item2.meta.authkey,_this.privs)) {
              return false
            }
            if(item2.children && item2.children.length>0) {
              item2.children = item2.children.filter(function (item3) {
                if(item3.meta.authkey && !_this.in_array(item3.meta.authkey,_this.privs)) {
                  return false
                }
                return item3.meta.isShow
              })
            }
            return item2.meta.isShow
          })
        }
        return true
      })
      return router
    },
    rsyncRouter() {
      var _this = this
      var check_parent = _this.$route.matched[2].parent
      var check_child = _this.$route.matched[2]
      var check_last = _this.$route.matched[3]
      var parentRouter = this.initRouter(this.$router.options.routes[2].children)
      parentRouter = parentRouter.filter(function (item, index) {
        item.active = false
        if(item.name == check_parent.name) {
          item.active = true
          _this.child = item.children
          _this.child = _this.child.filter(function (item2, index2) {
            _this.three = item2.children
            if(_this.three) {
              _this.three = _this.three.filter(function (item3, index3) {
                if(check_last && item3.name == check_last.name) {
                  item3.active = true
                } else {
                  item3.active = false
                }
                return item3.meta.isShow
              })
            }
            item2.children = _this.three
            if(item2.name == check_child.name) {
              item2.active = true
            } else {
              item2.active = false
            }
            return item2.meta.isShow
          })
        }
        return true
      })
      this.parent = parentRouter
      this.bread_crumb[0] = check_parent
      this.bread_crumb[1] = check_child
      if(check_last) {
        this.bread_crumb[2] = check_last
      }
    },
    changeOne(index) {
      var _this = this
      if(_this.parent[index].active || !_this.parent[index].children) {
        return false
      }
      _this.parent.filter(function (item,cindex) {
        item.active = cindex === index
        return item
      })
      this.bread_crumb[0] = _this.parent[index]
      _this.child = _this.parent[index].children
      _this.child.filter(function (item,idx) {
        item.active = idx===0
        item.children = item.children.filter(function (item2,idx2) {
          item2.active = idx===0 && idx2===0
          return item2.meta.isShow
        })
        return item.meta.isShow
      })
      this.bread_crumb[1] = _this.child[0]
      this.bread_crumb[2] = _this.child[0].children[0]
      _this.$router.push({name: _this.child[0].children[0].name})
    },
    changeTwo(index,cindex) {
      var _this = this
      if(_this.child[index].children[cindex].active) {
        return false
      }
      _this.bread_crumb[1] = _this.child[index]
      _this.bread_crumb[2] = _this.child[index].children[cindex]
      _this.child.filter(function (item,idx) {
        item.active = index===idx
        item.children = item.children.filter(function (item2,idx2) {
          item2.active = index===idx && cindex===idx2
          return item2.meta.isShow
        })
        return item.meta.isShow
      })
      _this.$router.push({name: _this.child[index].children[cindex].name})
    },
    getUserInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode == 0) {
          if(res.data.avatar) {
            this.circleUrl = res.data.avatar
          }
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    getMyPrivs() {
      var _this = this
      this.$api.merchant.myPrivs(function (res) {
        if(res.errcode == 0) {
          _this.privs = res.data.privs
          setFuncPriv(JSON.stringify(res.data))
          _this.rsyncRouter()
          _this.initData()
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    getConfig() {
      this.$api.merchant.sysConfig(res => {
        if(res.errcode==0) {
          this.config = res.data
          if(res.data.qq_map_key) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://map.qq.com/api/gljs?v=1.exp&key=" + res.data.qq_map_key;
            document.getElementsByTagName('head')[0].appendChild(script);
          }
          this.initSocket()
        }
      })
    },
    handleDrop(command) {
      var _this = this
      if(command == 'logout') {
        this.$api.merchant.authLogout(function (res) {
          if(res.errcode == 0) {
            removeToken()
            _this.success(res.errmsg)
            _this.$router.push({name: 'login'})
          } else {
            _this.fail(res.errmsg)
          }
        })
      } else if(command == 'myinfo') {
        _this.$router.push({name: 'person_index'})
      } else if(command == 'system_notice') {
        this.$router.push({name: 'sys_notice_system'})
      } else if(command == 'merchant_notice') {
        this.$router.push({name: 'sys_notice_merchant'})
      }
    },
    in_array(search,arr) {
      for(var i in arr){
        if(arr[i]==search){
          return true;
        }
      }
      return false;
    }
  }
};
</script>
<style scoped>
  .layout {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .tab-layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 220px;
    height: 100%;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  }
  .tab-layout * {
    transition: all .3s cubic-bezier(.645,.045,.355,1),border 0s,color .1s,font-size 0s;
  }
  .tab-logo {
    font-size: 28px;
    padding: 15px 0 15px 0;
  }
  .tab-one {
    background-color: #282c34;
    width: 64px;
    height: 100%;
    text-align: center;
    color: #fff;
  }
  .tab-one li {
    width: 54px;
    height: 54px;
    border-radius: 4px;
    margin-left: 5px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .tab-one li i {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
  .tab-one li .txt {
    font-size: 13px;
    font-weight: 500;
  }
  .tab-one li.active {
    background-color: #1890ff;
  }
  .tab-two {
    position: fixed;
    top: 0;
    left: 74px;
    width: 136px;
    text-align: center;
  }
  .tab-two .two-box::-webkit-scrollbar {
    width: 0;
  }
  .tab-two .title {
    color: #515a6e;
    display: inline-block;
    overflow: hidden;
    font-size: 20px;
    line-height: 55px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
  .tab-two .two-box {
    position: fixed;
    top: 55px;
    left: 74px;
    width: 136px;
    text-align: center;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding-bottom: 60px;
  }
  .tab-two .divider {
    display: block;
    height: 1px;
    width: 100%;
    color: #515a6e;
    border-top: 1px solid #f6f6f6;
    margin-top: 30px;
  }
  .tab-two .divider_txt {
    font-size: 14px;
    font-weight: bolder;
    background-color: #fff;
    margin-top: -12px;
    display: inline-block;
    position: absolute;
    padding: 0 15px;
    left: 10px;
    color: #88bdf5;
  }
  .tab-two ul {
    margin-top: 30px;
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
  }
  .tab-two li {
    text-align: left;
    height: 50px;
    overflow: hidden;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    cursor: pointer;
    transition: border-color .3s,background-color .3s,color .3s;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 20px;
  }
  .tab-two li i {
    margin-right: 6px;
  }
  .tab-two li.active {
    color: #1890ff;
    background-color: #e8f4ff;
  }
  .tab-two li:hover {
    color: #1890ff;
    background-color: #e8f4ff;
  }
  .top-layout {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    left: 220px;
    width: calc(100% - 220px);
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    padding: 0 15px;
    background-color: #fff;
  }
  .top-layout .nav {
    position: relative;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    border-bottom: 1px solid #f6f6f6;
  }
  .top-layout .nav .menu {
    color: #515a6e;
    font-size: 13px;
  }
  .top-layout .nav .menu i {
    margin-right: 4px;
  }
  .top-layout .showico {
    color: #515a6e;
    font-size: 16px;
    margin-right: 15px !important;
    cursor: pointer;
  }
  .top-layout .tab {
    height: 50px;
  }
  .top-layout .nav .uhead {
    float: right;
    margin-right: 10px;
  }
  .top-layout .nav .uhead .img {
    vertical-align: middle;
    margin-right: 10px;
    margin-top: -5px;
  }
  .top-layout .tab li {
    float: left;
    border: 1px solid #dcdfe6;
    height: 30px;
    line-height: 30px;
    margin: 10px 10px 0 0;
    padding: 0 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  .top-layout .tab li:hover {
    color: #1890ff;
    background: #e8f4ff;
    border: 1px solid #1890ff;
    transition: border-color .3s,background-color .3s,color .3s;
  }
  .top-layout .tab li:hover .close {
    display: inline-block !important;
  }
  .body-layout {
    box-sizing: border-box;
    padding: 70px 10px 0 232px;
  }
  .badge-box {
  }
  .badge {
    margin-right: 30px;
    display: inherit;
  }
  .badge i {
    font-size: 20px;
    color: #666;
  }
</style>
