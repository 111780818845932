import layout from '../view/layout'

const router = {
  name: 'channel',
  path: 'channel',
  component: layout,
  meta: {
    title: '渠道',
    ico: 'el-icon-help',
    authkey: 'channel'
  },
  children: [
    {
      name: '',
      path: 'weixin',
      component: () => import('../view/layout'),
      meta: {
        title: '微信',
        ico: '',
        isShow: true,
        authkey: 'channel_weixin'
      },
      children: [
        {
          name: 'channel_weixin_pic',
          path: 'pic',
          component: () => import('../view/channel/weixin/pic_index'),
          meta: {
            title: '图文管理',
            ico: '',
            isShow: true,
            authkey: 'channel_weixin_pic'
          }
        },
        {
          name: 'channel_weixin_pic_edit',
          path: 'pic/edit',
          component: () => import('../view/channel/weixin/pic_edit'),
          meta: {
            title: '图文管理',
            ico: '',
            isShow: false,
            authkey: 'channel_weixin_pic'
          }
        },
        {
          name: 'channel_weixin_menu',
          path: 'menu',
          component: () => import('../view/channel/weixin/menu'),
          meta: {
            title: '自定义菜单',
            ico: '',
            isShow: true,
            authkey: 'channel_weixin_menu'
          }
        }
      ]
    }
  ]
}

export default router
