import { fetch } from '@/utils/request';

// 会员
const memberIndex = (param, callback) => fetch('/shop/member/index','GET', param, callback);
const memberInfo = (param, callback) => fetch('/shop/member/detail','GET', param, callback);
const memberUpadte = (param, callback) => fetch('/shop/member/update','POST', param, callback);
const memberSetGroup = (param, callback) => fetch('/shop/member/setGroup','POST', param, callback);
const memberSetLable = (param, callback) => fetch('/shop/member/setLable','POST', param, callback);
const memberSetGrade = (param, callback) => fetch('/shop/member/setGrade','POST', param, callback);
const memberIntegralUpdate = (param, callback) => fetch('/shop/member/integral/update','POST', param, callback);
const memberBalanceUpdate = (param, callback) => fetch('/shop/member/balance/update','POST', param, callback);

// 会员分组
const memberGroupIndex = (param, callback) => fetch('/shop/member/group/index','GET', param, callback);
const memberGroupTree = (param, callback) => fetch('/shop/member/group/tree','GET', param, callback);
const memberGroupInfo = (param, callback) => fetch('/shop/member/group/detail','GET', param, callback);
const memberGroupAdd = (param, callback) => fetch('/shop/member/group/add','POST', param, callback);
const memberGroupEdit = (param, callback) => fetch('/shop/member/group/edit','POST', param, callback);
const memberGroupDel = (param, callback) => fetch('/shop/member/group/del','POST', param, callback);

// 会员标签
const memberLabelIndex = (param, callback) => fetch('/shop/member/label/index','GET', param, callback);
const memberLabelTree = (param, callback) => fetch('/shop/member/label/tree','GET', param, callback);
const memberLabelInfo = (param, callback) => fetch('/shop/member/label/detail','GET', param, callback);
const memberLabelAdd = (param, callback) => fetch('/shop/member/label/add','POST', param, callback);
const memberLabelEdit = (param, callback) => fetch('/shop/member/label/edit','POST', param, callback);
const memberLabelDel = (param, callback) => fetch('/shop/member/label/del','POST', param, callback);

// 会员等级
const memberGradeIndex = (param, callback) => fetch('/shop/member/grade/index','GET', param, callback);
const memberGradeInfo = (param, callback) => fetch('/shop/member/grade/detail','GET', param, callback);
const memberGradeAdd = (param, callback) => fetch('/shop/member/grade/add','POST', param, callback);
const memberGradeEdit = (param, callback) => fetch('/shop/member/grade/edit','POST', param, callback);
const memberGradeShenhe = (param, callback) => fetch('/shop/member/grade/shenhe','POST', param, callback);
const memberGradeDel = (param, callback) => fetch('/shop/member/grade/del','POST', param, callback);

// 会员积分
const memberIntegralHome = (param, callback) => fetch('/shop/member/integral/home','GET', param, callback);
const memberIntegralLog = (param, callback) => fetch('/shop/member/integral/log','GET', param, callback);
const memberIntegralSetGet = (param, callback) => fetch('/shop/member/integral/set','GET', param, callback);
const memberIntegralSetSave = (param, callback) => fetch('/shop/member/integral/set','POST', param, callback);
const memberIntegralTjTime = (param, callback) => fetch('/shop/member/integral/tj/time','GET', param, callback);

// 会员余额
const memberBillHome = (param, callback) => fetch('/shop/member/bill/home','GET', param, callback);
const memberBillLog = (param, callback) => fetch('/shop/member/bill/log','GET', param, callback);
const memberBillTjTime = (param, callback) => fetch('/shop/member/bill/tj/time','GET', param, callback);

// 会员佣金
const memberCommissionHome = (param, callback) => fetch('/shop/member/commission/home','GET', param, callback);
const memberCommissionLog = (param, callback) => fetch('/shop/member/commission/log','GET', param, callback);
const memberCommissionTjTime = (param, callback) => fetch('/shop/member/commission/tj/time','GET', param, callback);

export default {
  memberIndex,
  memberInfo,
  memberUpadte,
  memberSetGroup,
  memberSetLable,
  memberSetGrade,
  memberIntegralUpdate,
  memberBalanceUpdate,

  memberGroupIndex,
  memberGroupTree,
  memberGroupInfo,
  memberGroupAdd,
  memberGroupEdit,
  memberGroupDel,

  memberLabelIndex,
  memberLabelTree,
  memberLabelInfo,
  memberLabelAdd,
  memberLabelEdit,
  memberLabelDel,

  memberGradeIndex,
  memberGradeInfo,
  memberGradeAdd,
  memberGradeEdit,
  memberGradeShenhe,
  memberGradeDel,

  memberIntegralHome,
  memberIntegralLog,
  memberIntegralSetGet,
  memberIntegralSetSave,
  memberIntegralTjTime,

  memberBillHome,
  memberBillLog,
  memberBillTjTime,

  memberCommissionHome,
  memberCommissionLog,
  memberCommissionTjTime

}
