import { fetch } from '@/utils/request';

// 商品分类
const menuIndex = (param, callback) => fetch('/shop/goods/menu/index','GET', param, callback);
const menuInfo = (param, callback) => fetch('/shop/goods/menu/detail','GET', param, callback);
const menuAdd = (param, callback) => fetch('/shop/goods/menu/add','POST', param, callback);
const menuEdit = (param, callback) => fetch('/shop/goods/menu/edit','POST', param, callback);
const menuDel = (param, callback) => fetch('/shop/goods/menu/del','POST', param, callback);
const menuShenhe = (param, callback) => fetch('/shop/goods/menu/shenhe','POST', param, callback);
const menuAll = (param, callback) => fetch('/shop/goods/menu/list','GET', param, callback);

// 商品规格模板
const specsTemplateIndex = (param, callback) => fetch('/shop/goods/specsTemplate/index','GET', param, callback);
const specsTemplateInfo = (param, callback) => fetch('/shop/goods/specsTemplate/detail','GET', param, callback);
const specsTemplateAdd = (param, callback) => fetch('/shop/goods/specsTemplate/add','POST', param, callback);
const specsTemplateEdit = (param, callback) => fetch('/shop/goods/specsTemplate/edit','POST', param, callback);
const specsTemplateDel = (param, callback) => fetch('/shop/goods/specsTemplate/del','POST', param, callback);

// 商品评价
const evaluateIndex = (param, callback) => fetch('/shop/goods/evaluate/index','GET', param, callback);
const evaluateInfo = (param, callback) => fetch('/shop/goods/evaluate/detail','GET', param, callback);
const evaluateEdit = (param, callback) => fetch('/shop/goods/evaluate/add','POST', param, callback);
const evaluateReply = (param, callback) => fetch('/shop/goods/evaluate/reply','POST', param, callback);
const evaluateDel = (param, callback) => fetch('/shop/goods/evaluate/del','POST', param, callback);
const evaluateShenhe = (param, callback) => fetch('/shop/goods/evaluate/shenhe','POST', param, callback);

// 商品
const goodsIndex = (param, callback) => fetch('/shop/goods/index','GET', param, callback);
const goodsInfo = (param, callback) => fetch('/shop/goods/detail','GET', param, callback);
const goodsAdd = (param, callback) => fetch('/shop/goods/add','POST', param, callback);
const goodsEdit = (param, callback) => fetch('/shop/goods/edit','POST', param, callback);
const goodsDel = (param, callback) => fetch('/shop/goods/del','POST', param, callback);
const goodsShenhe = (param, callback) => fetch('/shop/goods/shenhe','POST', param, callback);
const goodsRecommend = (param, callback) => fetch('/shop/goods/recommend','POST', param, callback);
const changeStock = (param, callback) => fetch('/shop/goods/changeStock','POST', param, callback);
const goodsGuider = (param, callback) => fetch('/shop/goods/guider','POST', param, callback);

// 运费模板
const shipmentIndex = (param, callback) => fetch('/shop/goods/shipment/index','GET', param, callback);
const shipmentInfo = (param, callback) => fetch('/shop/goods/shipment/detail','GET', param, callback);
const shipmentAdd = (param, callback) => fetch('/shop/goods/shipment/add','POST', param, callback);
const shipmentEdit = (param, callback) => fetch('/shop/goods/shipment/edit','POST', param, callback);
const shipmentDel = (param, callback) => fetch('/shop/goods/shipment/del','POST', param, callback);

export default {
  menuIndex,
  menuInfo,
  menuAdd,
  menuEdit,
  menuDel,
  menuShenhe,
  menuAll,

  specsTemplateIndex,
  specsTemplateInfo,
  specsTemplateAdd,
  specsTemplateEdit,
  specsTemplateDel,

  evaluateIndex,
  evaluateInfo,
  evaluateEdit,
  evaluateReply,
  evaluateDel,
  evaluateShenhe,

  goodsIndex,
  goodsInfo,
  goodsAdd,
  goodsEdit,
  goodsDel,
  goodsShenhe,
  goodsRecommend,
  changeStock,
  goodsGuider,

  shipmentIndex,
  shipmentInfo,
  shipmentAdd,
  shipmentEdit,
  shipmentDel
}
