import { fetch } from '@/utils/request';

// 店铺
const shopInfo = (param, callback) => fetch('/shop/shop/info','GET', param, callback);
const shopEdit = (param, callback) => fetch('/shop/shop/edit','POST', param, callback);

// 装修
const designIndex = (param, callback) => fetch('/shop/design/index','GET', param, callback);
const designInfo = (param, callback) => fetch('/shop/design/detail','GET', param, callback);
const designEdit = (param, callback) => fetch('/shop/design/edit','POST', param, callback);
const designDel = (param, callback) => fetch('/shop/design/del','POST', param, callback);
const designSetHome = (param, callback) => fetch('/shop/design/sethome','POST', param, callback);
const designNavInfo = (param, callback) => fetch('/shop/design/nav/info','GET', param, callback);
const designNavEdit = (param, callback) => fetch('/shop/design/nav/edit','POST', param, callback);

// 报名
const bookIndex = (param, callback) => fetch('/shop/book/index','GET', param, callback);
const bookDel = (param, callback) => fetch('/shop/book/del','POST', param, callback);

export default {
  shopInfo,
  shopEdit,
  designIndex,
  designInfo,
  designEdit,
  designDel,
  designSetHome,
  designNavInfo,
  designNavEdit,
  bookIndex,
  bookDel
}
