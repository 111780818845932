import { fetch } from '@/utils/request';

// 优惠券
const couponIndex = (param, callback) => fetch('/shop/coupon/index','GET', param, callback);
const couponInfo = (param, callback) => fetch('/shop/coupon/detail','GET', param, callback);
const couponAdd = (param, callback) => fetch('/shop/coupon/add','POST', param, callback);
const couponEdit = (param, callback) => fetch('/shop/coupon/edit','POST', param, callback);
const couponDel = (param, callback) => fetch('/shop/coupon/del','POST', param, callback);
const couponShenhe = (param, callback) => fetch('/shop/coupon/shenhe','POST', param, callback);
const couponSend = (param, callback) => fetch('/shop/coupon/send','POST', param, callback);
const couponLog = (param, callback) => fetch('/shop/coupon/log','GET', param, callback);
const couponHexiao = (param, callback) => fetch('/shop/coupon/hexiao','POST', param, callback);
const couponSubDel = (param, callback) => fetch('/shop/coupon/sub/del','POST', param, callback);

// 充值
const rechargeIndex = (param, callback) => fetch('/shop/recharge/index','GET', param, callback);
const rechargeInfo = (param, callback) => fetch('/shop/recharge/detail','GET', param, callback);
const rechargeAdd = (param, callback) => fetch('/shop/recharge/add','POST', param, callback);
const rechargeEdit = (param, callback) => fetch('/shop/recharge/edit','POST', param, callback);
const rechargeDel = (param, callback) => fetch('/shop/recharge/del','POST', param, callback);
const rechargeShenhe = (param, callback) => fetch('/shop/recharge/shenhe','POST', param, callback);

// 预售
const presaleIndex = (param, callback) => fetch('/shop/presale/index','GET', param, callback);
const presaleInfo = (param, callback) => fetch('/shop/presale/detail','GET', param, callback);
const presaleAdd = (param, callback) => fetch('/shop/presale/add','POST', param, callback);
const presaleEdit = (param, callback) => fetch('/shop/presale/edit','POST', param, callback);
const presaleDel = (param, callback) => fetch('/shop/presale/del','POST', param, callback);
const presaleShenhe = (param, callback) => fetch('/shop/presale/shenhe','POST', param, callback);

export default {
  couponIndex,
  couponInfo,
  couponAdd,
  couponEdit,
  couponDel,
  couponShenhe,
  couponSend,
  couponLog,
  couponHexiao,
  couponSubDel,

  rechargeIndex,
  rechargeInfo,
  rechargeAdd,
  rechargeEdit,
  rechargeDel,
  rechargeShenhe,

  presaleIndex,
  presaleInfo,
  presaleAdd,
  presaleEdit,
  presaleDel,
  presaleShenhe
}
