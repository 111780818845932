import { fetch } from '@/utils/request';

// 基础
const cityTree = (callback) => fetch('/shop/common/getCityTree','GET',{}, callback);
const authLogin = (param, callback) => fetch('/shop/auth/login','POST',param, callback);
const authLogout = (callback) => fetch('/shop/auth/logout','POST',false, callback);
const myIndex = (callback) => fetch('/shop/my/index','GET',{}, callback);
const myUpdate = (param, callback) => fetch('/shop/my/update','POST',param, callback);
const myPrivs = (callback) => fetch('/shop/my/privs','GET',{}, callback);
const myUpdatePwd = (param, callback) => fetch('/shop/my/resetpwd','POST',param, callback);
const mySetSecret = (param, callback) => fetch('/shop/my/setSecret','POST',param, callback);
const addressTolocation = (param, callback) => fetch('/shop/common/address2location','POST',param, callback);

// 文件
const FileUpload = (param, callback) => fetch('/shop/common/uploadimg','POST',param, callback);
const fileList = (param, callback) => fetch('/shop/common/filelist','GET',param, callback);
const filedel = (param, callback) => fetch('/shop/common/filedel','POST',param, callback);

// 系统
const sysDetail = (callback) => fetch('/shop/sys/info','GET', {}, callback);
const sysEdit = (param, callback) => fetch('/shop/sys/update','POST', param, callback);
const sysConfig = (callback) => fetch('/shop/sys/config','GET', {}, callback);
const sysConfigDetail = (callback) => fetch('/shop/sys/config/info','GET', {}, callback);
const sysConfigEdit = (param, callback) => fetch('/shop/sys/config/update','POST', param, callback);
const sysFunctionList = (param,callback) => fetch('/shop/sys/function/list','GET', param, callback);

// 用户
const userIndex = (param, callback) => fetch('/shop/user/index','GET', param, callback);
const userDetail = (param, callback) => fetch('/shop/user/detail','GET', param, callback);
const userAdd = (param, callback) => fetch('/shop/user/add','POST', param, callback);
const userEdit = (param, callback) => fetch('/shop/user/edit','POST', param, callback);
const userDelete = (param, callback) => fetch('/shop/user/del','POST', param, callback);
const userShenhe = (param, callback) => fetch('/shop/user/shenhe','POST', param, callback);
const userVisitLog = (param, callback) => fetch('/shop/user/visitlog','GET', param, callback);

// 群组
const groupIndex = (param, callback) => fetch('/shop/group/index','GET', param, callback);
const groupDetail = (param, callback) => fetch('/shop/group/detail','GET', param, callback);
const groupAdd = (param, callback) => fetch('/shop/group/add','POST', param, callback);
const groupEdit = (param, callback) => fetch('/shop/group/edit','POST', param, callback);
const groupDelete = (param, callback) => fetch('/shop/group/del','POST', param, callback);

// 菜单
const menuIndex = (param, callback) => fetch('/shop/menu/index','GET', param, callback);

// 公众号/小程序
const getPreCode = (param, callback) => fetch('/shop/auth/getprecode','POST', param, callback);

// 支付配置
const paymentIndex = (param, callback) => fetch('/shop/payment/index','GET', param, callback);
const paymentEdit = (param, callback) => fetch('/shop/payment/edit','POST', param, callback);

// 配送设置
const deliveryInfo = (param, callback) => fetch('/shop/delivery/info','GET', param, callback);
const deliverySave = (param, callback) => fetch('/shop/delivery/edit','POST', param, callback);
const deliveryList = (param, callback) => fetch('/shop/delivery/list','GET', param, callback);
const deliveryMerchantList = (param, callback) => fetch('/shop/delivery/merchant/list','GET', param, callback);
const deliveryMerchantEdit = (param, callback) => fetch('/shop/delivery/merchant/edit','POST', param, callback);
const deliveryMerchantDel = (param, callback) => fetch('/shop/delivery/merchant/del','POST', param, callback);
const deliveryJkEdit = (param, callback) => fetch('/shop/delivery/jkEdit','POST', param, callback);
const deliveryJkChange = (param, callback) => fetch('/shop/delivery/jkChange','POST', param, callback);

// 隐私设置
const secretInfo = (param, callback) => fetch('/shop/secret/info','GET', param, callback);
const secretSave = (param, callback) => fetch('/shop/secret/edit','POST', param, callback);

// 自提点
const storeIndex = (param, callback) => fetch('/shop/store/index','GET', param, callback);
const storeDetail = (param, callback) => fetch('/shop/store/detail','GET', param, callback);
const storeAdd = (param, callback) => fetch('/shop/store/add','POST', param, callback);
const storeEdit = (param, callback) => fetch('/shop/store/edit','POST', param, callback);
const storeDelete = (param, callback) => fetch('/shop/store/del','POST', param, callback);
const storeShenhe = (param, callback) => fetch('/shop/store/shenhe','POST', param, callback);

// 模板消息管理
const messageIndex = (param, callback) => fetch('/shop/message/index','GET', param, callback);
const messageRsync = (param, callback) => fetch('/shop/message/rsync','POST', param, callback);
const messageShenhe = (param, callback) => fetch('/shop/message/shenhe','POST', param, callback);

// 通知公告
const noticeSystemList = (param, callback) => fetch('/shop/sys/notice/system','GET', param, callback);
const noticeSystemRead = (param, callback) => fetch('/shop/sys/notice/system','POST', param, callback);
const noticeSystemReadAll = (param, callback) => fetch('/shop/sys/notice/system/all','POST', param, callback);
const noticeMerchantList = (param, callback) => fetch('/shop/sys/notice/merchant','GET', param, callback);
const noticeMerchantRead = (param, callback) => fetch('/shop/sys/notice/merchant','POST', param, callback);
const noticeMerchantReadAll = (param, callback) => fetch('/shop/sys/notice/merchant/all','POST', param, callback);

export default {
  cityTree,
  authLogin,
  authLogout,
  myIndex,
  myUpdate,
  myPrivs,
  myUpdatePwd,
  mySetSecret,
  addressTolocation,
  FileUpload,
  fileList,
  filedel,
  sysDetail,
  sysEdit,
  sysConfig,
  sysConfigDetail,
  sysConfigEdit,
  sysFunctionList,
  userIndex,
  userDetail,
  userAdd,
  userEdit,
  userDelete,
  userShenhe,
  userVisitLog,
  groupIndex,
  groupDetail,
  groupAdd,
  groupEdit,
  groupDelete,
  menuIndex,
  getPreCode,
  paymentIndex,
  paymentEdit,
  deliveryInfo,
  deliverySave,
  deliveryList,
  deliveryMerchantList,
  deliveryMerchantEdit,
  deliveryMerchantDel,
  deliveryJkEdit,
  deliveryJkChange,
  secretInfo,
  secretSave,
  storeIndex,
  storeDetail,
  storeAdd,
  storeEdit,
  storeDelete,
  storeShenhe,
  messageIndex,
  messageRsync,
  messageShenhe,
  noticeSystemList,
  noticeSystemRead,
  noticeSystemReadAll,
  noticeMerchantList,
  noticeMerchantRead,
  noticeMerchantReadAll
}
