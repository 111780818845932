import { fetch } from '@/utils/request';

// 微信
const weixinList = (param, callback) => fetch('/shop/weixin/list','GET', param, callback);
const weixinXcxCategory = (param, callback) => fetch('/shop/weixin/xcx/category','GET', param, callback);
const weixinXcxSubmit = (param, callback) => fetch('/shop/weixin/xcx/submit','POST', param, callback);
const weixinXcxUpgrade = (param, callback) => fetch('/shop/weixin/xcx/upgrade','POST', param, callback);

export default {
  weixinList,
  weixinXcxCategory,
  weixinXcxSubmit,
  weixinXcxUpgrade
}
