import layout from '../view/layout'

const router = {
  name: 'marking',
  path: 'marking',
  component: layout,
  meta: {
    title: '营销',
    ico: 'el-icon-present',
    authkey: 'marking'
  },
  children: [
    {
      name: 'marking_coupon',
      path: 'coupon',
      component: () => import('../view/layout'),
      meta: {
        title: '优惠券',
        ico: '',
        isShow: true,
        authkey: 'marking_coupon'
      },
      children: [
        {
          name: 'marking_coupon_list',
          path: 'list',
          component: () => import('../view/marking/coupon/index'),
          meta: {
            title: '优惠券列表',
            ico: '',
            isShow: true,
            authkey: 'marking_coupon_list'
          }
        },
        {
          name: 'marking_coupon_edit',
          path: 'edit',
          component: () => import('../view/marking/coupon/edit'),
          meta: {
            title: '编辑优惠券',
            ico: '',
            isShow: false,
            authkey: 'marking_coupon_list'
          }
        },
        {
          name: 'marking_coupon_log',
          path: 'log',
          component: () => import('../view/marking/coupon/log'),
          meta: {
            title: '领取记录',
            ico: '',
            isShow: true,
            authkey: 'marking_coupon_log'
          }
        }
      ]
    },
    {
      name: 'marking_bargain',
      path: 'bargain',
      component: () => import('../view/layout'),
      meta: {
        title: '砍价',
        ico: '',
        isShow: true,
        authkey: 'marking_bargain'
      },
      children: [
        {
          name: 'marking_bargain_list',
          path: 'list',
          component: () => import('../view/marking/news/index'),
          meta: {
            title: '砍价列表',
            ico: '',
            isShow: true,
            authkey: 'marking_bargain_list'
          }
        },
        {
          name: 'marking_bargain_order',
          path: 'order',
          component: () => import('../view/sys/user/index'),
          meta: {
            title: '砍价订单',
            ico: '',
            isShow: true,
            authkey: 'marking_bargain_order'
          }
        },
        {
          name: 'marking_bargain_set',
          path: 'set',
          component: () => import('../view/sys/user/index'),
          meta: {
            title: '砍价设置',
            ico: '',
            isShow: true,
            authkey: 'marking_bargain_set'
          }
        }
      ]
    },
    {
      name: 'marking_fightgroup',
      path: 'fightgroup',
      component: () => import('../view/order/index'),
      meta: {
        title: '拼团',
        ico: '',
        isShow: true,
        authkey: 'marking_fightgroup'
      },
      children: [
        {
          name: 'marking_fightgroup_list',
          path: 'list',
          component: () => import('../view/marking/news/index'),
          meta: {
            title: '拼团列表',
            ico: '',
            isShow: true,
            authkey: 'marking_fightgroup_list'
          }
        },
        {
          name: 'marking_fightgroup_order',
          path: 'order',
          component: () => import('../view/sys/user/index'),
          meta: {
            title: '拼团订单',
            ico: '',
            isShow: true,
            authkey: 'marking_fightgroup_order'
          }
        },
        {
          name: 'marking_fightgroup_set',
          path: 'set',
          component: () => import('../view/sys/user/index'),
          meta: {
            title: '拼团设置',
            ico: '',
            isShow: true,
            authkey: 'marking_fightgroup_set'
          }
        }
      ]
    },
    {
      name: 'marking_seckill',
      path: 'seckill',
      component: () => import('../view/order/index'),
      meta: {
        title: '秒杀',
        ico: '',
        isShow: true,
        authkey: 'marking_seckill'
      },
      children: [
        {
          name: 'marking_fightgroup_list',
          path: 'list',
          component: () => import('../view/marking/news/index'),
          meta: {
            title: '拼团列表',
            ico: '',
            isShow: true,
            authkey: 'marking_fightgroup_list'
          }
        },
        {
          name: 'marking_fightgroup_order',
          path: 'order',
          component: () => import('../view/sys/user/index'),
          meta: {
            title: '拼团订单',
            ico: '',
            isShow: true,
            authkey: 'marking_fightgroup_order'
          }
        },
        {
          name: 'marking_fightgroup_set',
          path: 'set',
          component: () => import('../view/sys/user/index'),
          meta: {
            title: '拼团设置',
            ico: '',
            isShow: true,
            authkey: 'marking_fightgroup_set'
          }
        }
      ]
    },
    {
      name: 'marking_other',
      path: 'other',
      component: () => import('../view/layout'),
      meta: {
        title: '其他活动',
        ico: '',
        isShow: true,
        authkey: 'marking_other'
      },
      children: [
        {
          name: 'marking_recharge',
          path: 'recharge',
          component: () => import('../view/marking/other/recharge'),
          meta: {
            title: '充值活动',
            ico: '',
            isShow: true,
            authkey: 'marking_recharge'
          }
        },
        {
          name: 'marking_recharge_add',
          path: 'recharge/add',
          component: () => import('../view/marking/other/recharge_edit'),
          meta: {
            title: '添加充值活动',
            ico: '',
            isShow: false,
            authkey: 'marking_recharge'
          }
        },
        {
          name: 'marking_presale',
          path: 'presale',
          component: () => import('../view/marking/other/presale'),
          meta: {
            title: '预售活动',
            ico: '',
            isShow: true,
            authkey: 'marking_presale'
          }
        },
        {
          name: 'marking_presale_add',
          path: 'presale/add',
          component: () => import('../view/marking/other/presale_edit'),
          meta: {
            title: '添加预售活动',
            ico: '',
            isShow: false,
            authkey: 'marking_presale'
          }
        }
      ]
    },
    {
      name: 'marking_news',
      path: 'news',
      component: () => import('../view/layout'),
      meta: {
        title: '资讯',
        ico: '',
        isShow: true,
        authkey: 'news'
      },
      children: [
        {
          name: 'marking_news_index',
          path: 'index',
          component: () => import('../view/marking/news/index'),
          meta: {
            title: '资讯列表',
            ico: '',
            isShow: true,
            authkey: 'news_index'
          }
        },
        {
          name: 'news_edit',
          path: 'edit',
          component: () => import('../view/marking/news/edit'),
          meta: {
            title: '资讯添加',
            ico: '',
            isShow: false,
            authkey: 'news_index'
          }
        },
        {
          name: 'news_menu',
          path: 'menu',
          component: () => import('../view/marking/news/menu'),
          meta: {
            title: '分类列表',
            ico: '',
            isShow: true,
            authkey: 'news_menu'
          }
        }
      ]
    }
  ]
}

export default router
