import layout from '../view/layout'

const router = {
  name: 'member',
  path: 'member',
  component: layout,
  meta: {
    title: '会员',
    ico: 'el-icon-user',
    authkey: 'member'
  },
  children: [
    {
      name: '',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '会员',
        ico: '',
        isShow: true,
        authkey: 'member'
      },
      children: [
        {
          name: 'member_index',
          path: 'index',
          component: () => import('../view/member/index'),
          meta: {
            title: '会员管理',
            ico: '',
            isShow: true,
            authkey: 'member_index'
          }
        },
        {
          name: 'member_grade',
          path: 'grade',
          component: () => import('../view/member/member_grade'),
          meta: {
            title: '会员卡',
            ico: '',
            isShow: true,
            authkey: 'member_grade'
          }
        },
        {
          name: 'member_grade_add',
          path: 'grade/add',
          component: () => import('../view/member/member_grade_add'),
          meta: {
            title: '会员卡编辑',
            ico: '',
            isShow: false,
            authkey: 'member_grade'
          }
        },
        {
          name: 'member_group',
          path: 'group',
          component: () => import('../view/member/member_group'),
          meta: {
            title: '会员分组',
            ico: '',
            isShow: true,
            authkey: 'member_group'
          }
        },
        {
          name: 'member_label',
          path: 'label',
          component: () => import('../view/member/member_label'),
          meta: {
            title: '会员标签',
            ico: '',
            isShow: true,
            authkey: 'member_label'
          }
        }
      ]
    },
    {
      name: '',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '积分',
        ico: '',
        isShow: true,
        authkey: 'member_integarl'
      },
      children: [
        {
          name: 'member_integral_home',
          path: 'integral/home',
          component: () => import('../view/member/integral/home'),
          meta: {
            title: '积分概况',
            ico: '',
            isShow: true,
            authkey: 'member_integral_view'
          }
        },
        {
          name: 'member_integral_log',
          path: 'integral/log',
          component: () => import('../view/member/integral/log'),
          meta: {
            title: '积分记录',
            ico: '',
            isShow: true,
            authkey: 'member_integral_log'
          }
        },
        {
          name: 'member_integral_set',
          path: 'integral/set',
          component: () => import('../view/member/integral/set'),
          meta: {
            title: '积分设置',
            ico: '',
            isShow: true,
            authkey: 'member_integral_set'
          }
        }
      ]
    },
    {
      name: '',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '余额',
        ico: '',
        isShow: true,
        authkey: 'member_bill'
      },
      children: [
        {
          name: 'member_bill_home',
          path: 'bill/home',
          component: () => import('../view/member/bill/home'),
          meta: {
            title: '余额概况',
            ico: '',
            isShow: true,
            authkey: 'member_bill_view'
          }
        },
        {
          name: 'member_bill_log',
          path: 'bill/log',
          component: () => import('../view/member/bill/log'),
          meta: {
            title: '余额记录',
            ico: '',
            isShow: true,
            authkey: 'member_bill_log'
          }
        }
      ]
    },
    {
      name: '',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '佣金',
        ico: '',
        isShow: true,
        authkey: 'member_commission'
      },
      children: [
        {
          name: 'member_commission_view',
          path: 'commission/home',
          component: () => import('../view/member/commission/home'),
          meta: {
            title: '佣金概况',
            ico: '',
            isShow: true,
            authkey: 'member_commission_view'
          }
        },
        {
          name: 'member_commission_log',
          path: 'commission/log',
          component: () => import('../view/member/commission/log'),
          meta: {
            title: '佣金记录',
            ico: '',
            isShow: true,
            authkey: 'member_commission_log'
          }
        }
      ]
    }
  ]
}

export default router
