import layout from '../view/layout'

const router = {
  name: 'goods',
  path: 'goods',
  component: layout,
  meta: {
    title: '商品',
    ico: 'el-icon-shopping-bag-1',
    authkey: 'goods'
  },
  children: [
    {
      name: '',
      path: '',
      component: () => import('../view/layout'),
      meta: {
        title: '商品',
        ico: '',
        isShow: true,
        authkey: 'goods'
      },
      children: [
        {
          name: 'goods_index',
          path: 'index',
          component: () => import('../view/goods/index'),
          meta: {
            title: '商品管理',
            ico: '',
            isShow: true,
            authkey: 'goods_index'
          }
        },
        {
          name: 'goods_add',
          path: 'add',
          component: () => import('../view/goods/add'),
          meta: {
            title: '商品添加',
            ico: '',
            isShow: false,
            authkey: 'goods_index'
          }
        },
        {
          name: 'goods_menu',
          path: 'menu',
          component: () => import('../view/goods/menu'),
          meta: {
            title: '商品分类',
            ico: '',
            isShow: true,
            authkey: 'goods_menu'
          }
        },
        {
          name: 'goods_specs',
          path: 'template',
          component: () => import('../view/goods/specs_tempalte'),
          meta: {
            title: '规格模板',
            ico: '',
            isShow: true,
            authkey: 'goods_specs_template'
          }
        },
        {
          name: 'goods_evaluate',
          path: 'evaluate',
          component: () => import('../view/goods/evaluate'),
          meta: {
            title: '商品评价',
            ico: '',
            isShow: true,
            authkey: 'goods_evaluate'
          }
        },
        {
          name: 'goods_shipment',
          path: 'shipment',
          component: () => import('../view/goods/shipment'),
          meta: {
            title: '运费模板',
            ico: '',
            isShow: true,
            authkey: 'goods_shipment'
          }
        },
        {
          name: 'goods_shipment_add',
          path: 'shipment/add',
          component: () => import('../view/goods/shipment_add'),
          meta: {
            title: '添加运费模板',
            ico: '',
            isShow: false,
            authkey: 'goods_shipment'
          }
        }
      ]
    }
  ]
}

export default router
